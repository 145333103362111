<template>
    <div>
        <b-row>
            <b-col class="sponclass">
                <h3 class='tourHead'>
                  {{ data.full_name }}
                </h3>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-row class="topSecrow">
                    <b-col md="6" cols="12">
                        <b-nav pills>
                            <div>
                            <b-dropdown v-if="stats.ooms === ''" :text="'Report'">
                                <b-dropdown-item>No Stats</b-dropdown-item>
                            </b-dropdown>
                            <b-dropdown
                                v-else-if="Array.isArray(stats.ooms.ooms_entry)"
                                :text="data.short_name"
                            >
                                <b-dropdown-item
                                v-for="(ooms, index) in stats.ooms.ooms_entry"
                                :key="index + Math.random()"
                                v-on:click="pickStat"
                                :title="'-ooms-oom-' + ooms.code"
                                 v-show="ooms.show_app == 'B' || ooms.show_app == 'W'"
                                >{{ ooms.short_name }}</b-dropdown-item
                                >
                                <b-dropdown-item
                                v-on:click="pickStat"
                                v-for="(stats, index) in stats.stats.stats_entry"
                                :key="index + Math.random()"
                                :title="'-stats-stats-' + stats.code"
                                 v-show="stats.show_app == 'B' || stats.show_app == 'W'"
                                >{{ stats.full_name }}</b-dropdown-item
                                >
                            </b-dropdown>
                            <b-dropdown v-else :text="data.short_name">
                                <b-dropdown-item
                                v-on:click="pickStat"
                                :title="'-ooms-oom-' + stats.ooms.ooms_entry.code"
                                >{{ stats.ooms.ooms_entry.full_name }}</b-dropdown-item
                                >
                                <b-dropdown-item
                                v-on:click="pickStat"
                                v-for="(stats, index) in stats.stats.stats_entry"
                                :key="index + Math.random()"
                                :title="'-stats-stats-' + stats.code"
                                 v-show="stats.show_app == 'B' || stats.show_app == 'W'"
                                >{{ stats.full_name }}</b-dropdown-item
                                >
                            </b-dropdown>
                            </div>
                        </b-nav>
                    </b-col>
                    <b-col md="6" cols="12">
                        <b-nav pills>
                            <b-dropdown :text="this.id + ' Season'">
                            <b-dropdown-item
                                v-on:click="changeYear"
                                v-for="(seasons,
                                index) in tm_params.seasons.seasons_entry.slice().reverse()"
                                :key="index"
                                :title="seasons.code"
                                :year="seasons.code"
                                v-show="seasons.code > '210S'"
                                >{{ seasons.desc }}</b-dropdown-item
                            >
                            </b-dropdown>
                        </b-nav>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>
<script>
export default {
    name: "oomDropdown",
    components: {
    },
    props: [
        'config',
        'stat', 
        'data', 
        'id', 
        'tm_params', 
        'stats',
        'changeYear',
        'pickStat'
    ],
    data() {
        return {
        };
    },
    metaInfo() {
        return {
            title: "Stats",
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: this.description },
            ]
        }
    },
};
</script>
<style scoped>
::v-deep .dropdown-item {
  color: #212529!important;
}
img.eventSponsorLogo {
  /* width: 150px; */
  width: 200px;
}
.imgSponsor {
  width: 150px;
  padding: 70px 0 20px;
  float: right; 
}
.tourHead{
  color: #6C6C6C;
  font-weight: bold;
  font-size: 21px;
  /* padding: 70px 0 20px; */
  padding: 20px 0 0px;
  text-transform: capitalize;
}
.nav {
  display:block;
}
::v-deep button {
  color: #6C6C6C;
  background-color: white;
  border: 1px solid #dedddd;
  border-radius: 0px;
  width: 250px;
  text-align: left;
  padding: 10px;
  text-transform: capitalize;
  font-size: 11px;
}
::v-deep button::after {
  float: right;
  margin-top: 7px;
}
.noStats {
  text-align: center;
}
::v-deep button:hover {
  color: #263056;
  background-color: white;
}

.topSecrow {
  padding-top: 2%;
}
.tag {
  width: 30px;
}
.btn-group {
    width: 100%;
}
@media only screen and (max-width: 990px) {
  ::v-deep .mobCol {
    width: 100%;
    /* display: block; */
  }
  .bottomOom {
    width:100%;
  }
  .row.Mob {
    display: -webkit-inline-box;
    overflow-x: auto;
    width: 100%;
    white-space: nowrap;
    overflow: scroll;
  }
}
@media only screen and (max-width: 768px) {
  .imgSponsor {
    width: 150px;
    padding: 0;
    float: left;
  }
  .btn-group {
    width: 100%;
    margin-bottom: 15px;
  }
  .topThree {
    padding: 15px;
    margin: auto;
    text-align: center;
  }
}
@media only screen and (max-width: 480px) {
  .nav {
    /* float: left; */
  }
  .topThree {
    padding: 15px;
  }
  .imgSponsor {
    width: 150px;
    padding: 0;
    float: none;
  }
}
</style>
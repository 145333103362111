
   
<template>
  <div>
    <b-container>
      <div class="request-a-callback-form">
        <transition name="fade" mode="out-in">
          <div v-show="sent">
            <p>Thanks for contacting us, we will get back to you shortly...</p>
          </div>
        </transition>
        <b-form @submit="onSubmit">
          <b-row>
            <b-col md="12" cols="12" class="mobPad">
              <b-form-input
                type="text"
                v-model="names"
                placeholder="Your Name"
              />
              <br />
              <b-form-input
                type="text"
                v-model="email"
                placeholder="Email Address"
                required
              />
              <br />
              <b-form-input
                type="text"
                v-model="phone"
                placeholder="Phone Number"
              />
              <br />
              <b-form-input
                type="text"
                v-model="subject"
                placeholder="Subject"
              />
              <!-- <br />
                <b-form-checkbox
                  type="checkbox"
                  v-for="optionsDrop in optionsDrop"
                  :value="optionsDrop"
                  v-model="options"
                  :key="optionsDrop"
                  :aria-describedby="ariaDescribedby"
                  name="flavour-3a"
                >
                  {{ optionsDrop }}
                </b-form-checkbox>
              <br /> -->
              <br />
              <b-row>
                <b-col lg="6">
                  <b-form-checkbox
                    class="checkboxSize"
                    type="checkbox"
                    v-for="opt in optionsDrop"
                    :value="opt"
                    v-model="yourrecipient"
                    :key="opt"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-3a"
                  >
                    {{ opt.name }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <br />
            </b-col>
            <b-col md="12" cols="12">
              <b-form-textarea
                v-model="message"
                placeholder="Message"
                rows="11"
                cols="1"
                class="form-control"
              >
              </b-form-textarea>
              <br />
              <!-- <button
                data-text="submit"
                type="submit"
                class="moreBtn"
              >
                Submit
              </button> -->
                <button 
                type="submit" 
                class="btn btn-primary"
                data-text="submit"
                >
                    Submit
                </button>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-container>
  </div>
</template>

<style scoped>
.btn-primary {
    color: #fff;
    background-color: #2c2c2c;
    border-color: #2c2c2c;
    width: 100%;
}
.btn-primary:hover {
    color: #fff;
    background-color: #2c2c2c;
    border-color: #2c2c2c;
    width: 100%;
}
.blue {
    background-size: cover;
    padding-bottom: 5%;
    padding-top: 7%;
}
.moreBtn {
    border-color: transparent;
    font-size: 1.5rem;
    background-color: #00aeef;
    color: #f4f4f6;
    font-weight: 300;
    padding: 10px 75px;
    border-radius: 0;
}
::v-deep .form-control{
    font-size:18px
}
::v-deep .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    color: #6C6C6C;
    font-size: 13px;
}
::v-deep .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px;
}
::v-deep .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    content: "";
    background: 50% / 50% 50% no-repeat;
}
@media only screen and (max-width: 480px) {
    .mobPad{
        padding-bottom: 50px;
    }
}
</style>

<script>
import axios from "axios";
export default {
  name: "Form",
  data() {
    return {
      sent: false,
      contact: true,
      names: "",
      email: "",
      phone: "",
      subject: "",
      // options:"",
      optionsDrop: [
        {
          name: 'Ticket Queries',
          email: 'socialmedia@sunshinetour.com'
        },
        {
          name: 'Event Spectator Querie',
          email: 'socialmedia@sunshinetour.com'
        },
        {
          name: 'Website and App Feedback Queries',
          email: 'webadmin@sunshinetour.com'
        },
        {
          name: 'Competition Queries',
          email: 'socialmedia@sunshinetour.com'
        },
        {
          name: 'Qualifying School',
          email: 'info@sunshinetour.com'
        },
        {
          name: 'Vusi Ngubeni',
          email: 'info@sunshinetour.com'
        },
        {
          name: 'SA Open Championship',
          email: 'info@sunshinetour.com'
        },
        {
          name: 'Sunshine Ladies Tour',
          email: 'willie@sunshinetour.com'
        },
        {
          name: 'Altron Big Easy Tour',
          email: 'lmanyama@sunshinetour.com'
        },
        {
          name: 'General Queries',
          email: 'webadmin@sunshinetour.com'
        },
        {
          name: 'Media Queries',
          email: 'media@sunshinetour.com'
        },
        {
          name: 'Social Media Queries',
          email: 'socialmedia@sunshinetour.com'
        },
        {
          name: 'Commercial and Sponsorships',
          email: 'sponsorships@sunshinetour.com'
        },
        // {
        //   name: 'Matt',
        //   email: 'matt.edmeads@ocs-software.com'
        // },
      ],
      // optionsDrop: [
      //   'Ticket Queries',
      //   'Event Spectator Queries',
      //   'Website and App Feedback Queries',
      //   'Competition Queries',
      //   'Qualifying School',
      //   'Vusi Ngubeni',
      //   'SA Open Championship',
      //   'Sunshine Ladies Tour',
      //   'Altron Big Easy Tour',
      //   'General Queries'
      // ],
      message: "",
    };
  },
  props: ["blue"],
  methods: {
    onSubmit(e) {
      e.preventDefault();
      const formData = new FormData();
      formData.append("names", this.names);
      formData.append("email", this.email);
      formData.append("phone", this.phone);
      formData.append("subject", this.subject);
      formData.append("yourrecipient", this.yourrecipient.name); 
      formData.append("yourrecipient", this.yourrecipient.email); 
      formData.append("message", this.message);
        // alert(JSON.stringify(this.formData))
      // console.log(formData);
      axios
        .post(
          "https://updated.sunshinetour.com/wp-json/contact-form-7/v1/contact-forms/36345/feedback",
          formData
        )
        .then((response) => {
          this.sent = true;
          this.names = "";
          this.email = "";
          this.phone = "";
          this.subject = "";
          this.yourrecipient = "";
          this.yourrecipient = "";
          this.message = "";
          return response;
        });
    },
  },
};
</script>
<template>
    <div class="container">
        <h2 class='title' v-html="wpInfo[0].title.rendered">
        </h2>
        <b-row class="videoSection">
            <b-col lg="4" v-for="(vid, index) in wpInfo[0].acf.video_section" :key="index">
                <div class="videoPointer" @click="$bvModal.show('bv-modal-example' + index)">
                  <div v-html="vid.video" class="videoContent">
                  </div>
                </div>
                <div class="">
                  <h6 class="card-subtitle">{{vid.video_name}}</h6>
                </div>
                <b-modal :id="'bv-modal-example' + index" :title="vid.video_name" size="lg" class="modalBox">
                  <div v-html="vid.video">
                  </div>
                </b-modal>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
    name:"single",
    props: ["config"],
    components: {
    },
    data(){
        return {
            wpInfo:[],
            wpInfoTitle: ''
        }
    },
    metaInfo() {
        return {
            title: this.wpInfoTitle,
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: this.description },
            ]
        }
    },
    methods: {
        isMobile() {
            if( screen.width <= 760 ) {
                return true;
            }
            else {
                return false;
            }
        },
    },
    created() {
    const json = "wp/v2/pages?slug=" + this.$route.name + "&randomadd=" + new Date().getTime();
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.wpInfo = data;
        console.log("this.wpInfo -- ")
        console.log(this.wpInfo)
        this.wpInfoTitle = data[0].title.rendered;
        console.log("this.wpInfoTitle")
        console.log(this.wpInfoTitle)
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
    },
}
</script>

<style scoped>
.whiteBlock {
    padding-top: 50px;
    padding-bottom: 50px;
}
.GreyBlock {
    padding-top: 50px;
    padding-bottom: 50px;
}
.SecondSection {
    background-color: #F2F2F2;
}
.sectionBackground {
    background-repeat: no-repeat;
    background-size: cover;
    height: 450px;
    background-position: center;
}
.SponRow {
    margin: auto;
    text-align: center;
    /* display: table; */
}
::v-deep .conHeader.rowTwoContact.col-lg-6 > p {
    margin-bottom: 0px;
}
.rowTwoContact {
    margin-top: 60px;
}
.contactMargin {
    margin-top: 50px;
    margin-bottom: 50px;
}
.contactBack {
    background-color: #F0F0F0;
}
.contactuss {
    display: none;
}
::v-deep p {
    color: #6C6C6C;
    font-size: 13px;
    line-height: 1.8;
}
::v-deep .conHeader > p > strong {
    color: #000;
    font-size: 14px;
   
}
::v-deep .form-control {
    font-size: 13px;
    /* color: #D0D0D0; */
    border-radius: 0px;
}
::v-deep .btn-primary {
    color: #fff;
    background-color: #000;
    border-color: #000;
    width: 200px;
    border-radius: 0px;
    font-size: 11px;
    font-weight: bold;
    text-transform: capitalize;
}
::v-deep .btn-primary:hover {
    color: #fff;
    background-color: #000;
    border-color: #000;
    width: 200px;
}
.flip-card-back {
    padding: 30px;
}
.learnMore {
    text-align: center;
    border: 1px solid;
    padding: 10px;
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    height: 30px;
    line-height: 8px;
}
.learnMore:hover {
    background-color: #000;
}
.learnMore > a {
    color: #fff!important;
}
h2.sponsorHead {
    color: #fff;
    font-weight: bold;
    font-size: 15px;
    text-align: left;
}
p.sponsorText {
    text-align: left;
    color: #fff!important;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    height: 113px;
}
::v-deep p.sponsorText > p {
    color: #fff!important;
    font-size: 12px;
}
.flip-card {
    background-color: transparent;
    width: 300px;
    height: 300px;
    perspective: 1000px;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 247px;
    /* height: 255px; */
    /* height: 100%; */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    /* background-color: #bbb; */
    color: black;
}

.flip-card-back {
    background-color: #000;
    color: white;
    transform: rotateY(180deg);
}
.sponsorImage {
    width: 100%;
    margin-bottom: 30px;
}
.sponsorImage:hover {
    /* background-color: #000; */
}
span.description {
    color: #6C6C6C;
    font-size: 13px;
    margin-top: 30px;
    display: block;
}
p.infoLine {
    color: #6C6C6C;
    font-size: 13px;
}
p.titleHeader {
    color: #6C6C6C;
    font-weight: bold;
    font-size: 13px;
}
h2.howtoBuy {
    color: #6C6C6C;
    font-size: 21px;
    font-weight: bold;
    text-transform: capitalize;
    padding-bottom: 20px;
}
.sunboysImage {
    width: 100%;
    background-image: url(https://updated.sunshinetour.com/wp-content/uploads/2022/08/Screenshot-2022-07-11-at-13.36.26.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 306px;
    background-position: right;
}
.howToBuy {
    background-color: #F2F2F2;
    padding-top: 100px;
    padding-bottom: 100px;
}
.FirstP {
    margin-top: 50px!important;
    margin-bottom: 60px!important;
}
.aboutUsImage {
    width: 100%;
}
.sectionP {
    color: #6C6C6C;
    font-size: 13px;
    margin-bottom: 30px;
    margin-top: 20px;
}
h2.sunboys {
    color: #6C6C6C;
    text-transform: capitalize;
    font-size: 21px;
    font-weight: bold;
}
::v-deep label {
    width: 100%;
}
::v-deep textarea.wpcf7-form-control.wpcf7-textarea {
    width: 100%!important;
    border: 1px solid #ced4da;
}
::v-deep input.wpcf7-form-control.wpcf7-text.wpcf7-validates-as-required {
    width: 100%!important;
    border: 1px solid #ced4da;
    height: 35px;
}
::v-deep div#wpcf7-f34329-o1 {
    margin-top: -15px;
    display: inline-block;
    width: 100%!important;
}
.title{
    color: #6C6C6C;
    text-transform: capitalize;
    font-size: 21px;
    font-weight: bold;
    margin-top: 40px;
   
}
::v-deep  a{
    color: #000!important;
}
.content{
    padding-bottom: 50px;
    padding-top: 30px;
}
::v-deep .wpcf7-submit{
    width: 100%;
    background-color: #000;
    color: #fff;
    border: 0;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
}



@media screen and (max-width: 768px){
    .IframeCode {
        width: 100%;
    }
    .IframeCode > iframe {
        width: 100%;
    }
    .sponsorImage {
        width: 100%;
        margin-bottom: 15px;
    }
    .sponBack.col-sm-6.col-md-6.col-lg-6.col-6 {
        padding-left: 7px;
        padding-right: 7px;
    }
    .SponRow {
        margin: auto;
        text-align: center;
        display: inline-flex;
    }
    ::v-deep .btn-primary {
        color: #fff;
        background-color: #000;
        border-color: #000;
        width: 100%!important;
        border-radius: 0px;
        font-size: 11px;
        font-weight: bold;
        text-transform: capitalize;
    }
    ::v-deep .btn-primary:hover {
        color: #fff;
        background-color: #000;
        border-color: #000;
        width: 100%!important;
    }  
    .rowTwoContact {
        margin-top: 0px;
        margin-bottom: 60px;
    } 
    .conHeader.col-lg-6 {
        width: 50%;
    }
    .mobileContactBlock {
        margin-left: 15px;
    }
    .title {
        color: #6C6C6C;
        text-transform: capitalize;
        font-size: 21px;
        font-weight: bold;
        margin-top: 40px;
        padding-left: 0px;
       
    }
}

::v-deep .modal-dialog.modal-lg {
    margin-top: 18em;
}
.card-subtitle {
    color: #6C6C6C !important;
    /* font-weight: 300; */
    font-weight: 700;
    font-size: 13px;
    background-color: #fff;
    margin-top: 13px;
    margin-bottom: 15px !important;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.cardTitle {
    font-weight: 100;
    letter-spacing: -0.5px;
    color: #9aa5ac;
    margin-top: -2px;
    margin-bottom: -5px;
    font-size: 15px;
    color: #f2af15;
    text-transform: capitalize;
}
::v-deep iframe {
    width: 100%;
    height: 300px;
}
::v-deep .viewAll > a {
  color: #fff!important;
} 
.viewAll {
  background: #006737;
  border: none;
  color: #fff!important;
  width: 100px;
  border: 1px solid #E0E0E0;
  font-weight: bold;
  font-size: 11px;
  padding: 0;
  text-transform: capitalize;
  height: 35px;
  line-height: 6px;
  text-align: center;
  margin-top: 5.3em;
  float: right;
  line-height: 20px!important;
  display: block;
}
.viewAll:hover {
  background: #204232;
  border: none;
  color: #fff!important;
  width: 100px;
  border: 1px solid #E0E0E0;
  font-weight: bold;
  font-size: 11px;
  padding: 0;
  text-transform: capitalize;
  height: 35px;
  line-height: 6px;
  text-align: center;
  margin-top: 5.3em;
  float: right;
  line-height: 20px!important;
  display: block;
}
.seeAllNews {
  text-align: center;
  background: #f2af15;
  border: none;
  color: #fff!important;
  border-radius: 0;
  padding: 5px 20px;
  width: 300px;
  float: right;
  margin-top: 80px;
}
.seeAllNews:hover {
  text-align: center;
  background: rgb(33 66 50);
  border: none;
  color: #fff!important;
  border-radius: 0;
  padding: 5px 20px;
  width: 300px;
  float: right;
  margin-top: 80px;
}
.newslist {
  margin-top: 2em;
  padding-bottom: 50px;
}

.secTitle {
  color: #6C6C6C;
  font-weight: bold;
  font-size: 21px;
  margin-top: 0;
  padding-top: 3em;
  padding-bottom: 1em;
  text-transform: capitalize;
 
}
.videoContent {
  pointer-events: none;
}
.videoPointer {
  cursor: pointer;
}
.videoSection {
    margin-top: 50px;
    margin-bottom: 50px;
}
</style>
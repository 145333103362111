<template>
  <div id="oomPage">
    <b-container class="topSec">
      <OomDropdown
        :id="id"
        :stat="stat" 
        :data="data" 
        :config="config"
        :tm_params="tm_params"
        :stats="stats"
        :changeYear="changeYear"
        :pickStat="pickStat"
       />
    </b-container>
    <b-container>
      <div class="topThree">
        <TopThree :sponsor="data.event_sponsor_logo" :data="data.oom.oom_entry.slice(0,3)" :config="config" :fullname="data.full_name" :DEFIMAGESAMS3="DEFIMAGESAMS3"/>
      </div>
    </b-container>
    <div class="FollowingStrip">
      <b-container>
        <p class="followingText">{{data.last_tournament}}</p>
      </b-container>
    </div>
    <div class="GreenLineBlock"></div>
    <div class='tCont'>
      <b-row>
        <b-col>
          <h3 class="noStats" v-if="stats.ooms === ''">
            No Stats For This Season
          </h3>
          <StatsTable v-if="stats.length === 0" :id="id" :config="config"/>
          <StatsTable v-else :id="id" :stat="stat" :data="data" :config="config"/>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import StatsTable from "@/components/statsTable.vue";
import TopThree from "@/components/topThree.vue";
import OomDropdown from "@/components/oomDropdown.vue";

export default {
  name: "oom",
  components: {
    StatsTable,
    TopThree,
    OomDropdown
  },
  props: ["config"],
  data() {
    return {
      stats: [],
      id: this.$route.query.id,
      stat: "-ooms-oom-" + this.config.VUE_APP_OOM,
      data: [],
      tm_params: [],
    };
  },
  metaInfo() {
    return {
      title: "Stats",
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
      ]
    }
  },
  methods: {
    changeYear: function(event) {
      var id = event.target.getAttribute("title");
      return (
        (this.id = id),
        apiCall
          .tmStats(this.id)
          .then(({ data }) => {
            this.stats = data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            throw error;
          }),
        apiCall
          .stat(this.id, this.stat)
          .then(({ data }) => {
            this.data = data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            throw error;
          })
      );
    },
    pickStat: function(event) {
      var stat = event.target.getAttribute("title");
      return (
        (this.stat = stat),
        apiCall
          .stat(this.id, this.stat)
          .then(({ data }) => {
            this.data = data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            throw error;
          })
      );
    },
  },
  async mounted() {
    try {
      // First call, await for call to return
      let first_call = await apiCall.tmParamsBase();
      this.tm_params = first_call.data;
      this.DEFIMAGESAMS3 = first_call.data.tm_params.DEFIMAGESAMS3;
      this.loading = false;
      // Second call, await for call to return
      let second_call = await apiCall.tmStats(this.id);
      this.stats = second_call.data;
      this.loading = false;
      // Third call, await for call to return
      let third_call = await apiCall.stat(this.id, this.stat);
      this.data = third_call.data;
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  },
};
</script>
<style scoped>
::v-deep .followingText {
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  margin-top: 10px;
  padding-left: 10px;
  text-transform: capitalize;
}
::v-deep .FollowingStrip {
  background-color: #2c2c2c;
  height: 40px;
  display: inline-block;
  width: 100%;
  position: relative;
  top: 51px;
}
::v-deep .topSec {
  padding-bottom: 3%;
}
::v-deep .GreenLineBlock {
  height: 44px;
  background-color: #000;
  position: relative;
  /* top: 113.6px; */
  top: 50.6px;
}
::v-deep .tCont {
  /* padding: 270px 20px 20px;
  background-color: #fff;
  border-radius: 10px; */
  /* margin-top: -200px; */
  padding: 207px 20px 20px;
  background-color: #fff;
  border-radius: 10px;
  margin-top: -199.9px;
}
</style>


<template>
  <div id='search'> 
    <b-container>
      <h2>Search "{{ this.filter }}""</h2>
      <span style="display:flex;" v-if="!isHidden">
        <b-form-input v-model="text" placeholder="Search"></b-form-input>
        <b-button
          v-on:click="isHidden = true"
          :to="{
            name: 'search',
            query: { search: text },
          }"
          class="sIcon"
          :config="config"
        >
          <font-awesome-icon :icon="['fa', 'search']" />
        </b-button>
      </span>
      <b-table
        v-if="filter"
        :items="allposts"
        :fields="fields"
        stacked="md"
        show-empty
        small
        :filter="filter"
        :config="config"
      >
        <template #cell(better_featured_image)="row">
          <b-nav-item :to="'/' + row.item.slug">
            <h2 v-html="row.item.title.rendered"></h2>
          </b-nav-item>
          <span v-html="row.item.excerpt.rendered"></span> </template
      ></b-table>
    </b-container>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "search",
  props: ['config'],
  data() {
    return {
      allposts: [],
      filter: this.$route.query.search,
      fields: [{ key: "better_featured_image", label: "" }],
      filterOn: [],
      text:''
    };
  },
        created() {
    const json = "/wp/v2/posts?per_page=100"; 
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.allposts = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
#search{
  padding: 70px 0;
}
li {
  list-style-type: none;
}
.nav-link {
  padding: 0.5em 0;
}
.sIcon {
  background-color: #000;
  color: white !important;
}
.form-control{
  width: 30%;
}
</style>

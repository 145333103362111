<template>
    <div :class="{contactBack : $route.name === 'contact-us'}">
        <template v-if="$route.path !== '/beat-the-pro-ocs' && $route.path !== '/beat-the-pro'">
            <div v-if="$route.name === 'about-us'">
                <img class="aboutUsImage" src="https://updated.sunshinetour.com/wp-content/uploads/2022/08/SunshineBoys_header.png">
            </div>
            <div v-if="$route.name === 'partners-and-sponsors'">
                <img class="aboutUsImage" src="https://updated.sunshinetour.com/wp-content/uploads/2022/08/partners.png">
            </div>
            <b-container class='content'>
                <h2 class='title' v-html="wpInfo[0].title.rendered">
                </h2>
                <div v-if="$route.name === 'about-us'">
                    <b-row>
                        <b-col>
                            <div class="sectionP FirstP" v-html="wpInfo[0].acf.section_one"></div>
                        </b-col>
                    </b-row>
                    <h2 class="sunboys">The Sunshine Boys</h2>
                    <b-row>
                        <b-col>
                            <div class="sectionP" v-html="wpInfo[0].acf.section_two"></div>
                        </b-col>
                    </b-row>
                </div>
                <b-row v-if="$route.name === 'contact-us'" class="contactMargin">
                    <b-col cols="12" lg="6" class="mobileContactBlock">
                        <span class='text' :class="{contactuss : $route.name === 'contact-us'}" v-html="wpInfo[0].content.rendered"></span>
                        <b-row>
                            <!-- <b-col class="conHeader" v-html="wpInfo[0].acf.section_one" lg="6"></b-col> -->
                            <b-col class="conHeader" v-html="wpInfo[0].acf.section_two" lg="12"></b-col>
                            <b-col class="conHeader rowTwoContact" v-html="wpInfo[0].acf.section_three" lg="6"></b-col>
                            <b-col class="conHeader rowTwoContact" v-html="wpInfo[0].acf.section_four" lg="6"></b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" lg="6">
                        <div>
                            <Form />
                        </div>
                    </b-col>
                </b-row>
                <div v-else>
                    <span class='description' v-html="wpInfo[0].content.rendered"></span>
                </div>
            </b-container>
            <div class="howToBuy" v-if="$route.name === 'about-us'">
                <b-container>
                    <b-row>
                        <b-col lg="6" md="12" sm="12">
                            <h2 class="howtoBuy">How To Buy</h2>
                            <p class="titleHeader">limited Edition:</p>
                            <p class="infoLine">Contact Dan Retief personally - dan@netactive.co.za or call 082 853 4840</p>
                            <p class="titleHeader">Soft Cover:</p>
                            <p class="titleHeader">Overseas orders:</p>
                            <p class="infoLine">Contact Blue Weaver for a quotation on the cost plus shipping. Email: orders@blueweaver.co.za . Tel No: (021) 701-4477</p>
                            <p class="titleHeader">To Buy online (in SA):</p>
                            <p class="infoLine">Google Books Direct: The Sunshine Boys Available from the ProShop and book stores such as Exclusive Books, Bargain Books, Clarkes, Bargain Books, Estoril and many other outlets.</p>
                        </b-col>
                        <b-col lg="6" md="12" sm="12">
                            <div class="sunboysImage">
                            </div>
                        </b-col>
                    </b-row>
                </b-container>
            </div>
            <div v-if="$route.name === 'partners-and-sponsors'" >
                <b-container>
                    <template v-if="isMobile(true)">
                        <b-row class="SponRow" >
                            <b-col class="sponBack" v-for="(sponsor, index) in wpInfo[0].acf.partner_and_sponsor" :key="index" lg="6" md="6" sm="6" cols="6">
                                <div class="">
                                    <div class="">
                                        <a :href="'/partners-and-sponsors/' + sponsor.sponsor_link">
                                            <img id="imageSpon" class="sponsorImage" :src="sponsor.image">
                                        </a>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </template>
                    <template v-else>
                    <b-row class="SponRow" >
                        <b-col class="sponBack flip-card" v-for="(sponsor, index) in wpInfo[0].acf.partner_and_sponsor" :key="index" lg="3" md="3" sm="12">
                            <div class="flip-card-inner">
                                <div class="flip-card-front">
                                    <a :href="'/partners-and-sponsors/' + sponsor.sponsor_link">
                                        <img id="imageSpon" class="sponsorImage" :src="sponsor.image">
                                    </a>
                                </div>
                                <div class="flip-card-back">
                                    <h2 class="sponsorHead">{{sponsor.sponsor_name}}</h2> 
                                    <p class="sponsorText" v-html="sponsor.sponsor_description"></p> 
                                    <a :href="'/partners-and-sponsors/' + sponsor.sponsor_link">
                                        <div class="learnMore">
                                            Learn More
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    </template>
                </b-container>
            </div>
        </template>
        <template v-else>
            <div class="tour">
                <div class="sectionBackground" :style="{ backgroundImage: 'url(' + wpInfo[0].acf.banner_image + ''}">
                </div>
            </div>
            <div class="FistSection">
                <div class="container whiteBlock">
                    <h2 class='title' v-html="wpInfo[0].title.rendered">
                    </h2>
                    <p v-html="wpInfo[0].acf.first_block"></p>
                </div> 
            </div>
            <div class="SecondSection">
                <div class="container GreyBlock">
                    <b-row>
                        <b-col lg='6' sm='12'>
                            <div style="text-align:center" class="IframeCode" v-html="wpInfo[0].acf.second_block_youtube_url"></div>
                        </b-col>
                        <b-col lg='6' sm='12'>
                            <p v-html="wpInfo[0].acf.second_block_text"></p>
                        </b-col>
                    </b-row>
                </div>
            </div>
            <div class="ThirdSection">
                <div class="container whiteBlock">
                    <p v-html="wpInfo[0].acf.third_block"></p>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import apiCall from "@/services/api.js";
import Form from "@/components/form.vue";
export default {
    name:"single",
    props: ["config"],
    components: {
        Form,
    },
    data(){
        return {
            wpInfo:[],
            wpInfoTitle: ''
        }
    },
    metaInfo() {
        return {
            title: this.wpInfoTitle,
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: this.description },
            ]
        }
    },
    methods: {
        isMobile() {
            if( screen.width <= 760 ) {
                return true;
            }
            else {
                return false;
            }
        },
    },
    created() {
    const json = "wp/v2/pages?slug=" + this.$route.name + "&randomadd=" + new Date().getTime();
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.wpInfo = data;
        console.log("this.wpInfo -- ")
        console.log(this.wpInfo)
        this.wpInfoTitle = data[0].title.rendered;
        console.log("this.wpInfoTitle")
        console.log(this.wpInfoTitle)
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
    },
}
</script>

<style scoped>
.whiteBlock {
    padding-top: 50px;
    padding-bottom: 50px;
}
.GreyBlock {
    padding-top: 50px;
    padding-bottom: 50px;
}
.SecondSection {
    background-color: #F2F2F2;
}
.sectionBackground {
    background-repeat: no-repeat;
    background-size: cover;
    height: 450px;
    background-position: center;
}
.SponRow {
    margin: auto;
    text-align: center;
    /* display: table; */
}
::v-deep .conHeader.rowTwoContact.col-lg-6 > p {
    margin-bottom: 0px;
}
.rowTwoContact {
    margin-top: 60px;
}
.contactMargin {
    margin-top: 50px;
    margin-bottom: 50px;
}
.contactBack {
    background-color: #F0F0F0;
}
.contactuss {
    display: none;
}
::v-deep p {
    color: #6C6C6C;
    font-size: 13px;
    line-height: 1.8;
}
::v-deep .conHeader > p > strong {
    color: #000;
    font-size: 14px;
   
}
::v-deep .form-control {
    font-size: 13px;
    /* color: #D0D0D0; */
    border-radius: 0px;
}
::v-deep .btn-primary {
    color: #fff;
    background-color: #000;
    border-color: #000;
    width: 200px;
    border-radius: 0px;
    font-size: 11px;
    font-weight: bold;
    text-transform: capitalize;
}
::v-deep .btn-primary:hover {
    color: #fff;
    background-color: #000;
    border-color: #000;
    width: 200px;
}
.flip-card-back {
    padding: 30px;
}
.learnMore {
    text-align: center;
    border: 1px solid;
    padding: 10px;
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    height: 30px;
    line-height: 8px;
}
.learnMore:hover {
    background-color: #000;
}
.learnMore > a {
    color: #fff!important;
}
h2.sponsorHead {
    color: #fff;
    font-weight: bold;
    font-size: 15px;
    text-align: left;
}
p.sponsorText {
    text-align: left;
    color: #fff!important;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    height: 113px;
}
::v-deep p.sponsorText > p {
    color: #fff!important;
    font-size: 12px;
}
.flip-card {
    background-color: transparent;
    width: 300px;
    height: 300px;
    perspective: 1000px;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 247px;
    /* height: 255px; */
    /* height: 100%; */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    /* background-color: #bbb; */
    color: black;
}

.flip-card-back {
    background-color: #000;
    color: white;
    transform: rotateY(180deg);
}
.sponsorImage {
    width: 100%;
    margin-bottom: 30px;
}
.sponsorImage:hover {
    /* background-color: #000; */
}
span.description {
    color: #6C6C6C;
    font-size: 13px;
    margin-top: 30px;
    display: block;
}
p.infoLine {
    color: #6C6C6C;
    font-size: 13px;
}
p.titleHeader {
    color: #6C6C6C;
    font-weight: bold;
    font-size: 13px;
}
h2.howtoBuy {
    color: #6C6C6C;
    font-size: 21px;
    font-weight: bold;
    text-transform: capitalize;
    padding-bottom: 20px;
}
.sunboysImage {
    width: 100%;
    background-image: url(https://updated.sunshinetour.com/wp-content/uploads/2022/08/Screenshot-2022-07-11-at-13.36.26.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 306px;
    background-position: right;
}
.howToBuy {
    background-color: #F2F2F2;
    padding-top: 100px;
    padding-bottom: 100px;
}
.FirstP {
    margin-top: 50px!important;
    margin-bottom: 60px!important;
}
.aboutUsImage {
    width: 100%;
}
.sectionP {
    color: #6C6C6C;
    font-size: 13px;
    margin-bottom: 30px;
    margin-top: 20px;
}
h2.sunboys {
    color: #6C6C6C;
    text-transform: capitalize;
    font-size: 21px;
    font-weight: bold;
}
::v-deep label {
    width: 100%;
}
::v-deep textarea.wpcf7-form-control.wpcf7-textarea {
    width: 100%!important;
    border: 1px solid #ced4da;
}
::v-deep input.wpcf7-form-control.wpcf7-text.wpcf7-validates-as-required {
    width: 100%!important;
    border: 1px solid #ced4da;
    height: 35px;
}
::v-deep div#wpcf7-f34329-o1 {
    margin-top: -15px;
    display: inline-block;
    width: 100%!important;
}
.title{
    color: #6C6C6C;
    text-transform: capitalize;
    font-size: 21px;
    font-weight: bold;
    margin-top: 40px;
   
}
::v-deep  a{
    color: #000!important;
}
.content{
    padding-bottom: 50px;
    padding-top: 30px;
}
::v-deep .wpcf7-submit{
    width: 100%;
    background-color: #000;
    color: #fff;
    border: 0;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
}



@media screen and (max-width: 768px){
    .IframeCode {
        width: 100%;
    }
    .IframeCode > iframe {
        width: 100%;
    }
    .sponsorImage {
        width: 100%;
        margin-bottom: 15px;
    }
    .sponBack.col-sm-6.col-md-6.col-lg-6.col-6 {
        padding-left: 7px;
        padding-right: 7px;
    }
    .SponRow {
        margin: auto;
        text-align: center;
        display: inline-flex;
    }
    ::v-deep .btn-primary {
        color: #fff;
        background-color: #000;
        border-color: #000;
        width: 100%!important;
        border-radius: 0px;
        font-size: 11px;
        font-weight: bold;
        text-transform: capitalize;
    }
    ::v-deep .btn-primary:hover {
        color: #fff;
        background-color: #000;
        border-color: #000;
        width: 100%!important;
    }  
    .rowTwoContact {
        margin-top: 0px;
        margin-bottom: 60px;
    } 
    .conHeader.col-lg-6 {
        width: 50%;
    }
    .mobileContactBlock {
        margin-left: 15px;
    }
    .title {
        color: #6C6C6C;
        text-transform: capitalize;
        font-size: 21px;
        font-weight: bold;
        margin-top: 40px;
        padding-left: 0px;
       
    }
}
</style>
<template>
    <div>
        <b-container>
            <div>
                <b-row>
                    <b-col class="search">
                        <b-form-group
                        label="Seach"
                        label-for="filter-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                        >
                        <b-input-group size="sm">
                            <b-form-input
                            id="filter-input"
                            v-model="search"
                            type="search"
                            placeholder="SEARCH PLAYERS"
                            ></b-form-input>

                            <b-input-group-append>
                            <b-button :disabled="!search" @click="search = ''"
                                >
                                <img class="mag" src="https://updated.sunshinetour.com/wp-content/uploads/2022/09/magnifying_glass.png">
                                </b-button
                            >
                            </b-input-group-append>
                        </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
        </b-container>
        <div class="GreenLineBlock"></div>
        <div class='tableGroup'>
            <div>
                <div>
                    <div cols="12">
                        <table style="width:100%" class="container">
                        <thead>
                            <tr>
                            <th style="text-align:left">Name</th>
                            <th>Profile Image</th>
                            <th>Nationality</th>
                            <th style="text-align:left; padding-left:0px" class="hideMob">Represents</th>
                            <th class="hideMob">Turned Pro</th>
                            <th class="hideMob"></th>
                            </tr>
                        </thead>
                        </table>
                        <div v-for="(letter, index) in letters" :key="index">
                            <div class="letterAlpha">
                                <div colspan="11" class="letterPos container">
                                <span class="letter">
                                    {{ letter }}
                                </span>
                                </div>
                            </div>
                            <table style="width:100%" class="container">
                                <thead class="Hidetab">
                                    <tr>
                                        <th style="text-align:left"></th>
                                        <th></th>
                                        <th></th>
                                        <th style="text-align:left; padding-left:0px" class="hideMob"></th>
                                        <th class="hideMob"></th>
                                        <th class="hideMob"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <div class="container" style="display:contents">
                                        <tr v-for="(player, index) in alphaContacts(letter)" :key="index">
                                            <td style="text-align:left; padding-left: 15px;">
                                                <a :href="'/playerprofile/' + player.REFNO" class="NamePlayers">
                                                {{ player.FULL_NAME }}
                                                </a>
                                            </td>
                                            <td>
                                              <template v-if="player.PHOTOS.PHOTO.PHOTO_IMAGE == 'p_nul.gif'">
                                                <img class="playerPhotoDummy" src='https://ocs-sport.ams3.cdn.digitaloceanspaces.com/sst/2023/02/Player_Table_Grey.png' />
                                              </template>
                                              <template v-else>
                                                <img class="playerPhoto" :src="DEFIMAGESAMS3 + 'sst' + '/media/photos/' + player.PHOTOS.PHOTO.PHOTO_IMAGE" />
                                              </template>
                                            </td>
                                            <td>
                                                <img
                                                :class="flag"
                                                :src="
                                                    ( config.VUE_APP_FLAG_URL + player.NAT)
                                                    | lowercase
                                                "
                                                />
                                            </td>
                                            <td style="text-align:left" class="hideMob">
                                                <p v-if="Object.keys(player.REPRESENTS).length === 0">
                                                -
                                                </p>
                                                <p v-else>
                                                {{ player.REPRESENTS }}
                                                </p>
                                            </td>
                                            <td class="hideMob">
                                                {{ player.TURNED_PRO.replace('/', '' ,).replace('/', '' ,) }}
                                            </td>
                                            <td class="hideMob">
                                                <a :href="'/playerprofile' + '/' + player.REFNO" class="moreInfoLink">
                                                <span class="moreINFO">More Info
                                                    <span class="iconRight">
                                                    <font-awesome-icon
                                                        class="icon"
                                                        :icon="['fa', 'chevron-right']"
                                                    />
                                                    </span>
                                                </span>
                                                </a>
                                            </td>
                                        </tr>                  
                                    </div>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    </div>
</template>
<script>
export default {
  name: "PlayersList",
  props:[
    'season',
    'config', 
    'fields',
    'letters',
    'items',
    'flag',
    'DEFIMAGESAMS3',
    'filterOn',
    'filter'
  ],
  components:{

  },
  data() {
    return {
      search: ''
    };
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }

      return value.toLowerCase() + ".svg"; 
    },
  },
  methods: {
    alphaContacts: function(l) {
      return this.items.filter(i => {
        return i.FULL_NAME.toLowerCase().indexOf(l.toLowerCase()) === 0 && i.FULL_NAME.toLowerCase().includes(this.search.toLowerCase());
      });
    }
  }
};
</script>
<style scoped>
.playerPhotoDummy {
  width: 75px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.Hidetab >tr > th{
  background-color: transparent ;  
  padding: 0;
}
img.mag {
  width: 15px;
}
.moreInfoLink {
  color: #000!important;
}
.moreInfoLink:hover {
  text-decoration: none;
}
span.letter {
  /* padding-left: 12px; */
}
td.letterPos {
  text-align: left;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 13px;
}
.letterAlpha {
  background: #2c2c2c;
  color: #fff;
  width: 100%;
  /* position: absolute; */
  /* left: 0; */
  padding-top: 10px;
  padding-bottom: 10px;
}
.GreenLineBlock {
  height: 50px;
  background-color: #000;
  position: relative;
  top: 70px;
}
::v-deep td.namePlayer {
  text-align: left;
  padding-left: 15px;
}
::v-deep th.namePlayer {
  text-align: left;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #6C6C6C;
}
span.iconRight {
  margin-left: 10px;
}
span.moreINFO {
  color: #6C6C6C;
  font-weight: bold;
  font-size: 13px;
}
span.moreINFO:hover {
  color: #000;
  font-weight: bold;
  font-size: 13px;
  text-decoration: none;
}
th.namePlayer {
  text-align: left;
  padding-left: 0px;
}
td.namePlayer {
  text-align: left;
  padding-left: 0px;
}
::v-deep td.text-center.represents {
  text-align: left!important;
}
::v-deep th.text-center.represents {
  text-align: left!important;
  padding-left: 0px;
}
td.text-center.represents {
  text-align: left!important;
}
th.namePlayer {
  text-align: left;
  padding-left: 0px;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: bold;
}
td.namePlayer {
  text-align: left;
}
.form-control {
  border: 1px solid #E0E0E0;  
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  border-color: #E0E0E0;
  border-left: #E0E0E0;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 500px;
  margin: auto;
}
input#filter-input {
  /* border-radius: 10px 0px 0px 10px; */
  border-radius: 0px;
  padding: 18px;
}
a.NamePlayers {
  color: #6C6C6C!important;
  text-align: left;
  font-weight: bold;
}
a.NamePlayers:hover {
  color: #000!important;
  text-decoration: none;
  text-align: left;
  font-weight: bold;
}
li {
  list-style: none;
}
#players {
  padding-top: 30px;
}
.letterSearch > a {
  color: #fff !important;
  margin: 0px 6px;
  font-size: 16px;
  font-weight: bold;
}
.letterSearch {
  display: inline-block !important;
  margin-top: 5px;
}
.letCol {
  text-align: center;
}
.tThree{
  padding: 30px 0;
}
.title {
  padding: 0px 0;
  color: #6C6C6C;
  font-weight: bold;
  font-size: 21px;
  text-transform: capitalize;
  margin-top: 50px;
 
}
p.HeaderTitle {
  font-weight: bold;
  color: #000;
}
.searchBackground {
  background: #000;
  height: 60px;
  margin: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.tableGroup{
  padding: 0;
  background-color: white;
  border-radius: 10px;
  margin-top: 23px;
}
.profileLink > .nav-link {
  background-color: white;
  color: #000 !important;
  text-align: left;
  text-decoration: underline;
  font-size: 22px;
  padding: 0;
}
.fRow {
  border-bottom: 1px solid #ddd;
  margin-right: -10px;
}
.playDet > .col {
  border-left: 1px solid #ddd;
}
.playDet > .col:first-child {
  border: none;
}
.playDet {
  padding-top: 20px;
}
::v-deep div > label.text-sm-right {
  display: none !important;
}
::v-deep th {
  background-color: #000;
  width: 17%;
  padding-left: 0px;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: bold;
  position: sticky;
  top: 0;
  padding: 13px;
  color: #ffffff;
  text-align: center;
}

::v-deep td {
  text-align: center;
  font-size: 13px;
  vertical-align: middle;
}
p {
  margin: 0;
}
::v-deep .nav-link {
  color: white !important;
  background-color: #000;
  /* width: 140px; */
  width: 100%;
}

::v-deep .btn {
  background-color: #fff;
  z-index: 999;
  opacity: 1;
  color: #6c757d!important
}

.playerPhoto {
  width: 40%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.flag {
  width: 30px;
  box-shadow: 0 0 3px #555;
}
.insideflag {
  vertical-align: initial;
  width: 30px;
  box-shadow: 0 0 3px #555;
}
.profileButton {
  padding-left: 5%;
}

.details {
  padding-top: 3%;
}
::v-deep tr {
  /* background-color: #f2f2f2; */
  padding-top: 8px;
  padding-bottom: 8px;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #dee2e6;
}

.search {
  padding-bottom: 0px;
  padding-top: 10px;
}

.mb-0 {
  width: 100%;
}

@media only screen and (max-width: 990px) {
  ::v-deep .playerFlag {
    width: 35px;
    height: 35px;
    background: black;
    border-radius: 30px;
    margin-top: 32px;
    margin-left: 0px;
  }
}
@media only screen and (max-width: 768px) {
  .hideMob {
    display: none;
  }
  .GreenLineBlock {
    height: 50px;
    background-color: #000;
    position: relative;
    top: 70px;
    display: none;
  }
  .searchBackground {
    height: 100px;
  }
  .playerPhoto {
    width: 130px;
  }
}
@media only screen and (max-width: 767px) {
  ::v-deep td.moreInfo {
    display: none!important;
  }
  ::v-deep .table.b-table.b-table-stacked-md > tbody > tr > [data-label] > div {
    display: inline-block;
    width: calc(100% - 40%);
    padding: 0 0 0 calc(1rem / 2);
    margin: 0;
    text-align: left;
  }
  ::v-deep td.namePlayer {
    text-align: left;
    padding-left: 5px!important;
  }
  ::v-deep .table th, .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 0px solid #dee2e6!important;
  }
  ::v-deep .table.b-table.b-table-stacked-md > tbody > tr > ::before {
    content: attr(data-label);
    width: 40%;
    float: left!important;
    text-align: left!important;
    overflow-wrap: break-word;
    font-weight: bold;
    font-style: normal;
    padding: 0 calc(1rem / 2) 0 0;
    margin: 0;
    color: #D0D0D0;
    font-size: 10px;
  }
  ::v-deep td.profileImage {
    display: none!important;
  }
  ::v-deep .table th, .table td {
    border-top: 0px solid #dee2e6!important;
  }
}
@media only screen and (max-width: 500px) {
  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  .playerFlag {
    width: 35px;
    height: 35px;
    background: black;
    border-radius: 30px;
    margin-top: 32px;
    margin-left: 20px!important;
  }
  .tableGroup {
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    margin-top: 40px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .tThree {
    padding: 50px 0;
    padding-left: 0px;
    padding-right: 0px;
  }
  ::v-deep .nav-link {
    width: 35%;
  }
  .playerPhoto {
    width: 130px;
  }
  .letterSearch {
    display: none !important;
  }
  ::v-deep td::before {
    text-align: left !important;
  }
  ::v-deep td > div {
    text-align: right;
  }
  .mb-0 {
    width: 100%;
  }
}
</style>